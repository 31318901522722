import React from "react";
import { ReactSVG } from "react-svg";
import CardItemStatus from "./CardItemStatus";

export default class CardItem extends React.Component {

  render() {
    let gin = this.props.children;

    let showText = false;
    let showMore = false;

    return (
      <div class="max-w-sm rounded overflow-hidden shadow-2xl">
        <img class="pb-4 w-full" src={gin.image} alt="Bottle" />
        <div class="px-6 py-4">
          <div class="mb-1 min-h-16 font-bold text-2xl uppercase">{gin.name}</div>
          <div class="mb-2 text-sm">{gin.distillery}</div>
          {showText ? <p class="text-gray-700 text-base">{gin.description}</p> : <div />}
        </div>
        <div class="px-6 gap-2 grid grid-cols-2 content-center">
          <div class="inline-flex">
            <ReactSVG class="pr-2" src="./assets/wine_bar.svg" />
            <p>{gin.type}</p>
          </div>
          <div class="inline-flex">
            <ReactSVG class="pr-2" src="./assets/percent.svg" />
            <p>{gin.alcohol} vol</p>
          </div>
          <div class="inline-flex col-span-2">
            <ReactSVG class="pr-2" src="./assets/home_pin.svg" />
            <p>{gin.city}, {gin.country}</p>
          </div>
        </div>
        <div class="px-6 pt-4 pb-10 flex justify-between items-center">
          <CardItemStatus code={gin.status} />
          {showMore ?
            <button class="bg-gray-300 hover:bg-gray-400 text-gray-800 px-3 py-2 rounded-full inline-flex">
              <ReactSVG class="pr-2" src="./assets/expand_circle_right.svg" />
              <span class="font-semibold">Details</span>
            </button>
            : <div />
          }
        </div>
      </div>
    );
  }
}