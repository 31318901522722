import React from "react";

export default class CardListFilter extends React.Component {

  state = {
    isClicked: false
  }

  handleFilter = () => {
    if (this.state.isClicked) this.props.removeFilter(this.props.children.field, this.props.children.key);
    else this.props.addFilter(this.props.children.field, this.props.children.key);

    this.setState({
      isClicked: !this.state.isClicked
    });
  }

  render() {
    const style = this.state.isClicked ? "bg-blue-500 hover:bg-blue-700 text-white" : "bg-transparent hover:bg-blue-500 text-blue-700 hover:text-white border border-blue-500 hover:border-transparent";

    return (
      <button class={"px-3 py-2 rounded-full " + style} onClick={this.handleFilter}>
        <span class="font-semibold">{this.props.children.value}</span>
      </button>
    );
  }
}