import React from "react";

export default class Footer extends React.Component {

  render() {
    return (
      <footer class="my-5 bg-white dark:bg-gray-800">
        <div class="w-full mx-auto max-w-screen-xl p-5">
          <span class="text-base text-gray-500 dark:text-gray-400">
            © 2023 <a href="https://it-mw.de/" class="hover:underline">IT-MW</a>. All Rights Reserved.
          </span>
        </div>
      </footer>
    );
  }
}