import { createClient } from "@supabase/supabase-js";

export default class GinApiClient {

  constructor() {
    const supabaseUrl = "https://lzspudwetvhbrjnlqexh.supabase.co";
    const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx6c3B1ZHdldHZoYnJqbmxxZXhoIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDM1MTA3MTcsImV4cCI6MjAxOTA4NjcxN30.DFU6Ndch7Hgjv8-Cu5eZjYgaQVEELmg8Kx4GZQvgxr4";
  
    this.client = createClient(supabaseUrl, supabaseKey);
    this.filter = [];
  }

  async getList() {
    let query = this.client
      .from("gin")
      .select("id, name, distillery, status, type, alcohol, country, city, image");
    
    this.filter.forEach(filterItem => {
      if(filterItem.method === "eq") query = query.eq(filterItem.key, filterItem.value);
    });

    let { data } = await query;
    return data;
  }

  addFilter(field, value) {
    this.filter.push({
      method: "eq",
      key: field,
      value: value
    });
  }

  removeFilter(field, value) {
    let position = null;

    this.filter.forEach((index, filter) => {
      if(filter.key === field && filter.value === value) position = index; 
    });

    this.filter.splice(position, 1);
  }

  clearFilter() {
    this.filter = [];
  }
}