import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import CardList from "../Card/CardList";

export default class App extends React.Component {

  render() {
    return (
      <div className="container mx-auto my-7">
        <Header />
        <CardList />
        <Footer />
      </div>
    );
  }

}