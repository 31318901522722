import React from "react";
import CardItem from "./CardItem";
import GinApiClient from "../../api/GinApiClient";
import CardListFilter from "./CardListFilter";

export default class CardList extends React.Component {

  state = {
    filters: [
      {
        field: "status",
        key: "GREEN",
        value: "verfügbar"
      },
      {
        field: "status",
        key: "ORANGE",
        value: "fast leer"
      },
      {
        field: "status",
        key: "RED",
        value: "leer"
      },
      {
        field: "country",
        key: "DE",
        value: "Deutschland"
      },
      {
        field: "country",
        key: "UK",
        value: "England"
      },
    ],
    items: [],
    total: 0
  }

  constructor(props) {
    super(props);

    this.client = new GinApiClient();
  }

  componentDidMount() {
    this.refreshList();
  }

  addFilter = (field, key) => {
    this.client.addFilter(field, key);
    this.refreshList();
  }

  removeFilter = (field, key) => {
    this.client.removeFilter(field, key);
    this.refreshList();
  }

  refreshList = () => {
    this.client.getList().then(gins => this.setState({
      items: gins,
      total: gins.length
    }));
  }

  render() {
    let filters = this.state.filters.map(filter => <CardListFilter addFilter={this.addFilter} removeFilter={this.removeFilter}>{filter}</CardListFilter>);
    let items = this.state.items.map(item => <CardItem key={item.id}>{item}</CardItem>);

    return (
      <>
        <div class="flex flex-row justify-center mt-5 mb-10 space-x-5">
          {filters}
        </div>

        <div class="flex flex-wrap gap-10 justify-center">
          {this.state.total === 0 ? <div class="font-semibold text-xl">Keine Einträge!</div> : items}
        </div>
      </>
    );
  }
}