import React from "react";

export default class CardItemStatus extends React.Component {

  state = {
    color: null,
    text: null
  }

  componentDidMount() {
    switch(this.props.code) {
      case "GREEN":
        this.setState({ color: "bg-green-500", text: "verfügbar"});
        break;
      case "ORANGE":
        this.setState({ color: "bg-orange-400", text: "fast leer"});    
        break;
      case "RED":
      default:
        this.setState({ color: "bg-red-500", text: "leer"});    
        break;
    }
  }

  render() {    
    return (
      <div class="inline-flex space-x-2">
        <span class={"min-h-6 max-h-6 min-w-6 max-w-6 rounded-full " + this.state.color}></span>
        <p class="font-semibold">{ this.state.text }</p>
      </div>
    );
  }
}